.message-view-content {
  .voucher-product-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 340px;

    .voucher-product-card-title {
      padding: 5px 5px 0 5px;
      font-size: 15px;
      color: #333333;
      font-weight: bold;
    }

    .voucher-product-card-products {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .voucher-product-card-product {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &:not(:last-child) {
        border-bottom: 1px solid #E0E0E0;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }

    .voucher-product-card-product-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      width: 70%;
      height: 62px;
    }

    .voucher-product-card-product-icon {
      width: 62px;
      height: 62px;
      border-radius: 10px;
    }

    .voucher-product-card-product-title {
      font-size: 12px;
      color: #333333;
      font-weight: bold;
    }

    .voucher-product-card-product-price {
      font-size: 16px;
      color: #FF3D00;
      font-weight: 500;
    }

    .voucher-product-card-footer {
      display: flex;
      justify-content: center;
      border: 1px solid #FF3D00;
      color: #FF3D00;
      border-radius: 5px;
      padding: 6px 0;
      cursor: pointer;
      font-size: 14px;
      width: 100%;
    }
  }
}